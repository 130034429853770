import React, { useMemo } from "react"
import { graphql, PageProps, Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import { ImageObject } from "schema-dts"
import { helmetJsonLdProp } from "react-schemaorg"
import { Layout } from "../components/Layout"

const Root = styled.div`
  margin: 1rem;
`

const Title = styled.h1`
  font-family: PirataOne;
  font-size: 3rem;
  line-height: 1;
  margin-top: 1rem;
`

const Description = styled.p`
  font-family: serif;
  font-size: 0.9rem;
  font-style: italic;
  margin: 6px 0;
`

const MainImage = styled.img`
  width: 100%;
`

const RelatedItems = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`

const RelatedItem = styled(GatsbyLink)`
  opacity: 0.33;
  filter: grayscale(0.8);
`

interface QueryProps {
  item: {
    subcategories: {
      name: string
      slug: string
      category: {
        name: string
        slug: string
      }[]
    }[]
    image: {
      title: string
      description: string
      file: {
        name: string
      }
    }
  }
  relatedItems: {
    nodes: {
      slug: string
      image: {
        title: string
        file: {
          name: string
        }
      }
    }[]
  }
}

const ImagePostTemplate: React.FC<PageProps<QueryProps>> = ({ data }) => {
  const subcategory = useMemo(() => data.item.subcategories[0], [data.item.subcategories])

  return (
    <Layout
      title={data.item.image.title}
      description={data.item.image.description}
      imageUrl={`/images/${data.item.image.file.name}`}
      breadcrumbs={[
        { name: subcategory.category[0].name, slug: subcategory.category[0].slug },
        { name: subcategory.name, slug: subcategory.slug },
        { name: data.item.image.title },
      ]}
    >
      <Helmet
        script={[
          helmetJsonLdProp<ImageObject>({
            "@context": "https://schema.org",
            "@type": "ImageObject",
            caption: data.item.image.title,
            description: data.item.image.description,
            contentUrl: `${process.env.GATSBY_DOMAIN}/images/${data.item.image.file.name}`,
            representativeOfPage: true,
            width: {
              "@type": "QuantitativeValue",
              name: "512px",
            },
            height: {
              "@type": "QuantitativeValue",
              name: "512px",
            },
          }),
        ]}
      />
      <Title>{data.item.image.title}</Title>

      <Root>
        <Description>{data.item.image.description}</Description>
        <picture>
          <source srcSet={`/images/${data.item.image.file.name.replace(".jpeg", ".webp")}`} />
          <MainImage src={`/images/${data.item.image.file.name}`} alt={data.item.image.title} />
        </picture>
        <RelatedItems>
          {[...data.relatedItems.nodes].slice(0, 2).map(item => (
            <RelatedItem key={item.slug} to={`/${item.slug}/`} aria-label={item.image.title}>
              <picture>
                <source srcSet={`/images/${item.image.file.name.replace(".jpeg", ".webp")}`} />
                <img src={`/images/${item.image.file.name}`} alt={item.image.title} />
              </picture>
            </RelatedItem>
          ))}
        </RelatedItems>
      </Root>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $lang: String!, $subcategories: [String]!) {
    item: contentfulImagePost(id: { eq: $id }) {
      subcategories {
        name
        slug
        category {
          name
          slug
        }
      }
      image {
        title
        description
        file {
          name: fileName
        }
      }
    }
    relatedItems: allContentfulImagePost(
      limit: 2
      filter: {
        id: { ne: $id }
        node_locale: { eq: $lang }
        subcategories: { elemMatch: { id: { in: $subcategories } } }
      }
    ) {
      nodes {
        slug
        image {
          title
          file {
            name: fileName
          }
        }
      }
    }
  }
`

export default ImagePostTemplate
